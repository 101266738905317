import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { PainAreaKey } from "shared-interfaces";
import {
  ReferralCodeResponse,
  useAssessmentCreateMutation,
  useUserCreateMutation,
  useUserModifyMutation,
  authSetToken,
  getReferralDetails,
  getToken,
  getUser,
  useAppDispatch,
  useAppSelector,
} from "shared-redux";

import { t, theme } from "shared-utilities";
import {
  Icon,
  IconKind,
  Button,
  GenderQuestionView,
  LocationQuestionView,
  PainAreaQuestionView,
} from "src/components";
import styled, { css } from "styled-components/macro";
import { ReactComponent as LogoSvg } from "../assets/ascenti-logo.svg";
import Heading from "../components/texts/Heading";
import { gatherMetadata } from "../utilities/common";
import { getDeviceIdentifier } from "../utilities/storage";
import useIsMobile from "../utilities/useIsMobile";
import { Assessment } from "./Assessment";

const Header = styled.header`
  position: fixed;
  width: 100%;
  min-height: 76px;
  padding: 0 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  top: 0;
  background: #fff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 769px) {
    position: absolute;
    min-height: 76px;
  }
  @media (max-width: 768px) {
    min-height: 56px;
  }
`;

const Text = styled.div``;

const Wrapper = styled.div`
  width: 100%;
  //flex: 1;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 40px;
  padding: 0 16px 60px;

  @media (min-width: 769px) {
    margin-top: 80px;
  }

  @media (max-width: 768px) {
    flex: 1;
  }
`;

const BackIcon = styled.button`
  padding: 0;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Logo = styled.div`
  display: none;

  @media (min-width: 769px) {
    display: block;
    padding-bottom: 10px;
  }
`;

const HeadingStyle = css`
  @media (min-width: 769px) {
    padding-right: 120px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  @media (min-width: 769px) {
    margin-bottom: 20px;
  }
`;

export const AssessmentStaticQuestionsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const [activeStep, setActiveStep] = useState<number>(
    parseInt(new URLSearchParams(location.search).get("step") || "1", 10)
  );
  const [activePain, setActivePain] = useState<PainAreaKey | null>(null);
  const [activeGender, setActiveGender] = useState<number | null>(null);
  const [isPregnant, setIsPregnant] = useState<boolean>(false);
  const [activeLocation, setActiveLocation] = useState<string | null>(null);

  // State
  const [loading, setLoading] = useState<boolean>(false);

  // Store
  const referralDetails = useAppSelector(
    getReferralDetails
  ) as ReferralCodeResponse;
  const token = useAppSelector(getToken);
  const user = useAppSelector(getUser);

  const [
    dispatchCreateAssessment,
    { isLoading: isCreatingAssessment, isSuccess, isError },
  ] = useAssessmentCreateMutation();
  const [
    dispatchModifyUser,
    { isLoading: isModifyingUser, isSuccess: modifyUserSuccess },
  ] = useUserModifyMutation();
  const [
    dispatchCreateUser,
    { isLoading: isCreatingUser, data: response, isSuccess: createUserSuccess },
  ] = useUserCreateMutation();

  const isDisabled =
    (activeStep === 1 && activeGender) ||
    (activeStep === 2 && activeLocation) ||
    (activeStep === 3 && activePain) ||
    (activeStep === 4 && activePain);

  // Methods
  const handleCreateUser = async () => {
    const { organisation, code, patient } = referralDetails;

    const timezone = // allow overriding of timezone for testing purposes
      process.env.REACT_APP_TZ ||
      new Intl.DateTimeFormat().resolvedOptions().timeZone;
    let language = // allow overriding of language for testing purposes
      process.env.REACT_APP_LANG ||
      navigator.language ||
      window.navigator.language;
    // ensure language is in the format "en" and not "en-US" etc
    if (language.includes("-")) [language] = language.split("-");
    // ensure language is either "en" or "da" - others are not supported by the API
    if (!["en", "da"].includes(language)) language = "en";

    const timezoneOffset = // allow overriding of timezone offset for testing purposes
      parseInt(process.env.REACT_APP_TZ_OFFSET as string, 10) ||
      new Date().getTimezoneOffset();

    const newUser = {
      device: {
        deviceId: getDeviceIdentifier(),
        ...gatherMetadata(),
      },
      organisation: organisation!,
      referralCode: code,
      timezone,
      timezoneOffset,
      language,
      country: activeLocation,
      medicalInformation: {
        gender:
          // eslint-disable-next-line no-nested-ternary
          activeGender === null
            ? "unknown"
            : activeGender === 1
            ? "male"
            : "female",
        isPregnant,
      },
    };

    // If ref code doesn't provide patient details then create without
    if (
      (referralDetails && referralDetails.codeType !== "Unique") ||
      !patient
    ) {
      // eslint-disable-next-line
      return dispatchCreateUser(newUser as any);
    }

    return dispatchCreateUser({
      ...newUser,
      firstName: patient.firstName,
      lastName: patient.surname,
      email: patient.emailAddress!,
      medicalInformation: {
        ...newUser.medicalInformation,
        dateOfBirth: patient.dateOfBirth,
      },
      // eslint-disable-next-line
    } as any);
  };

  useEffect(() => {
    if (!createUserSuccess) return;
    if (response) {
      dispatch(authSetToken(response.data.token));
    }
  }, [createUserSuccess]);

  // Create assessment
  useEffect(() => {
    const createAssessment = async () => {
      if (token && !isCreatingAssessment && activePain) {
        await dispatchCreateAssessment({ painArea: activePain });
        navigate("/symptoms");
      }
    };
    if (token && (modifyUserSuccess || createUserSuccess)) createAssessment();
  }, [token, modifyUserSuccess, createUserSuccess]);

  useEffect(() => {
    if (isCreatingAssessment || isCreatingUser) return setLoading(true);
    if (!isCreatingAssessment && !isError && !isSuccess)
      return setLoading(false);
    if ((!isCreatingAssessment || !isModifyingUser) && (isError || isSuccess))
      return setLoading(true);
  }, [isCreatingAssessment, isCreatingUser, loading, isError, isSuccess]);

  const getGender = () => {
    switch (activeGender) {
      case 1:
        return "male";
      case 2:
        return "female";
      default:
        return "unknown";
    }
  };

  const updateActiveStepHandler = async () => {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
    navigate(`?step=${nextStep}`, { replace: false });

    if (nextStep === 4 && !user?.id) {
      await handleCreateUser();
      return;
    }

    if (nextStep === 4) {
      await dispatchModifyUser({
        medicalInformation: {
          gender: getGender(),
          isPregnant,
        },
      });
    }
  };

  const navigationHandler = () => {
    const previousStep = activeStep - 1;
    setActiveStep(previousStep);
    navigate(`?step=${previousStep}`, { replace: false });
  };

  const renderActiveStep = () => {
    switch (activeStep) {
      case 1: {
        return (
          <GenderQuestionView
            isPregnant={isPregnant}
            activeGender={activeGender}
            setIsPregnant={setIsPregnant}
            updateActiveGender={setActiveGender}
          />
        );
      }
      case 2: {
        return (
          <LocationQuestionView updateActiveLocation={setActiveLocation} />
        );
      }
      case 3: {
        return <PainAreaQuestionView updateActivePain={setActivePain} />;
      }
      case 4: {
        return <PainAreaQuestionView updateActivePain={setActivePain} />;
      }
      default: {
        return (
          <GenderQuestionView
            isPregnant={isPregnant}
            activeGender={activeGender}
            setIsPregnant={setIsPregnant}
            updateActiveGender={setActiveGender}
          />
        );
      }
    }
  };

  const renderButton = () => {
    if (activeStep === 3 && activePain === null)
      return (
        <Button
          bgColor={theme.palette.BACKGROUND}
          isFullWidth={isMobile}
          isRoundedCorners={!isMobile}
          loading={loading}
          handleClick={updateActiveStepHandler}
        >
          <Text
            css={[
              theme.typography[theme.typography.FONT_NAME].REGULAR_BOLD_HEADING,
              { color: theme.palette.PRIMARY },
            ]}
          >
            Select a pain area to continue
          </Text>
        </Button>
      );

    return (
      <Button
        bgColor={theme.palette.BUTTON_PRIMARY}
        isFullWidth={isMobile}
        isRoundedCorners={!isMobile}
        loading={loading}
        isActive={!!isDisabled}
        handleClick={updateActiveStepHandler}
      >
        <Text
          css={[
            theme.typography[theme.typography.FONT_NAME].REGULAR_BOLD_HEADING,
            { color: theme.palette.WHITE },
          ]}
        >
          {t("btn_next")}
        </Text>
      </Button>
    );
  };

  const syncActiveStepWithUrl = useCallback(() => {
    const urlParams = new URLSearchParams(location.search);
    const step = parseInt(urlParams.get("step") || "1", 10);
    setActiveStep(step);
  }, [location.search]);

  useEffect(() => {
    syncActiveStepWithUrl();
  }, [location.search, syncActiveStepWithUrl]);

  return (
    <Assessment>
      <Header>
        <HeaderWrapper>
          <BackIcon type="button" onClick={navigationHandler}>
            <Icon kind={IconKind.BACK} />
          </BackIcon>

          <Logo>
            <LogoSvg />
          </Logo>
          <Heading
            color={theme.palette.TOP_BAR_TEXT}
            size="regular"
            bold
            text="Getting to know you"
            marginBottom="0px"
            style={[{ lineHeight: theme.spacingPx(2) }, HeadingStyle]}
          />
          <div />
        </HeaderWrapper>
      </Header>
      <Wrapper>{renderActiveStep()}</Wrapper>
      <ButtonWrapper>{renderButton()}</ButtonWrapper>
    </Assessment>
  );
};
